





































































































































































import { Component, Vue } from 'vue-property-decorator'
import { DropDownController, AttendanceController } from '@/services/request.service'
import moment from 'moment'
import GroupTransfer from '@/components/GroupTransfer.vue'
import Upload from '@/components/Upload.vue'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    GroupTransfer,
    Upload,
  },
})
export default class LeaveEditor extends Vue {
  private leaveEditor: any
  private candidate: Array<any> = []
  private canLeave: boolean = false
  private dataLoading: boolean = false
  private formItemLayout: any = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }
  private student: any = {
    studentId: undefined,
    name: undefined,
  }
  private teachers: Array<any> = []
  private students: Array<any> = []
  private selectedStudents: Array<any> = []
  private loading: boolean = false
  private groupTransferVisible = false

  private get leaveTypes(): any {
    return [
      {
        key: 'holiday',
        label: this.$t('leaveApproval.holiday'),
      },
      {
        key: 'illness',
        label: this.$t('leaveApproval.illness'),
      },
      {
        key: 'exams',
        label: this.$t('leaveApproval.exams'),
      },
      {
        key: 'personal',
        label: this.$t('leaveApproval.personal'),
      },
      {
        key: 'others',
        label: this.$t('leaveApproval.others'),
      },
    ]
  }

  private addStudent(): void {
    this.groupTransferVisible = true
    this.candidate = this.students
  }

  private created() {
    this.getDropDownInfo()
  }

  private getDropDownInfo(): any {
    this.dataLoading = true
    Promise.all([DropDownController.getAuthStudentsGroupBySection(true)])
      .then(res => {
        this.students = res[0].data.map((grade, index) => {
          return {
            id: index,
            title: grade.value || this.$t('transfer.ungrouped'),
            expand: false,
            children: grade.subOptions.map(student => {
              return {
                id: student.key,
                title: student.value,
                titleWithRelation: `${student.value} (${student.extraValue || ''})`.trim(),
              }
            }),
          }
        })
      })
      .finally(() => (this.dataLoading = false))
  }

  private getSelectedData(data): void {
    this.selectedStudents = data
    this.leaveEditor.setFieldsValue({
      studentIds: this.selectedStudents.map(item => item.id),
    })
  }

  private takeLeave(): void {
    this.loading = true
    this.leaveEditor.validateFields((err: any, values: any) => {
      if (err) {
        this.loading = false
        return
      } else {
        let condition = {
          type: values.type,
          startTime: values.startTime.valueOf(),
          endTime: values.endTime.valueOf(),
          reason: values.reason,
          studentIds: this.selectedStudents.map(item => item.id),
          resourceIds: values.attachments.map(item => item.uid),
        }
        AttendanceController.saveLeaveApplication(condition)
          .then(res => {
            this.canLeave = true
            this.$route.meta.refresh = true
            this.$message.success(this.$tc('common.saveSuccess'))
            this.$router.push({ name: 'leaveApproval' })
          })
          .catch(err => console.log(err))
          .finally(() => (this.loading = false))
      }
    })
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (!this.canLeave) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private cancel(): void {
    this.$router.back()
  }

  private disabledDate(current): any {
    let currentSchoolYear = this.$store.state.currentSchoolYear
    if (!Object.keys(currentSchoolYear).length) return false
    return (
      current &&
      (current < moment(currentSchoolYear.startTime).startOf('day') ||
        current > moment(currentSchoolYear.endTime).startOf('day'))
    )
  }

  private onTimeChange(start): void {
    let otherTime = this.leaveEditor.getFieldValue(start ? 'endTime' : 'startTime')
    if (!otherTime) return
    this.$nextTick(() => {
      if (start) {
        this.leaveEditor.validateFields(['endTime'], { force: false }, (err: any, values: any) => {
          if (err) {
            return
          }
        })
      } else {
        this.leaveEditor.validateFields(
          ['startTime'],
          { force: false },
          (err: any, values: any) => {
            if (err) {
              return
            }
          }
        )
      }
    })
  }

  private validateStudent(rule, value, cb): void {
    if (this.selectedStudents.length === 0) {
      cb(this.$t('tips.selectStudents'))
    } else {
      cb()
    }
  }

  private validateTime(rule, value, cb): void {
    let otherTime = this.leaveEditor.getFieldValue(rule.start ? 'endTime' : 'startTime')

    if (!otherTime) {
      cb()
      return
    } else if (
      (value.isAfter(otherTime) && rule.start) ||
      (value.isBefore(otherTime) && !rule.start)
    ) {
      cb(
        this.$t(
          rule.start ? 'leaveApproval.startLaterThanEnd' : 'leaveApproval.endEarlierThanStart'
        )
      )
    } else {
      cb()
    }
  }

  private beforeCreate(): void {
    this.leaveEditor = this.$form.createForm(this)
  }

  private removeStudent(id): void {
    const index = this.selectedStudents.findIndex(student => student.id === id)
    this.selectedStudents.splice(index, 1)
  }
}
