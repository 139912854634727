var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"leave-editor",attrs:{"layout":"horizontal","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"form":_vm.leaveEditor,"autocomplete":"nope"}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.student'),"required":""}},[_c('div',{staticStyle:{"margin-bottom":"-16px"}},[_c('div',{staticClass:"flex-column",staticStyle:{"margin-bottom":"16px"}},[(_vm.selectedStudents.length)?_c('div',{staticClass:"tag-container"},_vm._l((_vm.selectedStudents),function(item){return _c('a-tag',{key:item.id,attrs:{"visible":"","closable":""},on:{"close":function($event){return _vm.removeStudent(item.id)}}},[_vm._v(_vm._s(item.title))])}),1):_vm._e(),_c('a-button',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'studentIds',
            {
              rules: [
                {
                  validator: _vm.validateStudent,
                } ],
            } ]),expression:"[\n            'studentIds',\n            {\n              rules: [\n                {\n                  validator: validateStudent,\n                },\n              ],\n            },\n          ]"}],attrs:{"block":"","type":"dashed","icon":"plus","loading":_vm.dataLoading},on:{"click":_vm.addStudent}},[_vm._v(_vm._s(_vm.$t('message.addStudents')))])],1)])]),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('leaveApproval.leaveType')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'type',
        {
          rules: [
            {
              required: true,
              message: _vm.$t('tips.selectLeaveType'),
            } ],
        } ]),expression:"[\n        'type',\n        {\n          rules: [\n            {\n              required: true,\n              message: $t('tips.selectLeaveType'),\n            },\n          ],\n        },\n      ]"}],staticClass:"filter-input",attrs:{"placeholder":_vm.$t('common.select')}},_vm._l((_vm.leaveTypes),function(item){return _c('a-select-option',{key:item.key},[_vm._v(_vm._s(item.label))])}),1)],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t("common.startTime"),"required":""}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'startTime',
        {
          validateFirst: true,
          rules: [
            {
              required: 'true',
              message: _vm.$t('tips.selectTime'),
            },
            {
              validator: _vm.validateTime,
              start: true,
            } ],
        } ]),expression:"[\n        'startTime',\n        {\n          validateFirst: true,\n          rules: [\n            {\n              required: 'true',\n              message: $t('tips.selectTime'),\n            },\n            {\n              validator: validateTime,\n              start: true,\n            },\n          ],\n        },\n      ]"}],staticStyle:{"width":"100%"},attrs:{"showTime":{
        format: 'HH:mm',
      },"disabledDate":_vm.disabledDate,"format":"YYYY-MM-DD HH:mm"},on:{"change":function () { return _vm.onTimeChange(true); }}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.endTime'),"required":""}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'endTime',
        {
          validateFirst: true,
          rules: [
            {
              required: true,
              message: _vm.$t('tips.selectTime'),
            },
            {
              validator: _vm.validateTime,
              start: false,
            } ],
        } ]),expression:"[\n        'endTime',\n        {\n          validateFirst: true,\n          rules: [\n            {\n              required: true,\n              message: $t('tips.selectTime'),\n            },\n            {\n              validator: validateTime,\n              start: false,\n            },\n          ],\n        },\n      ]"}],staticStyle:{"width":"100%"},attrs:{"showTime":{
        format: 'HH:mm',
      },"format":"YYYY-MM-DD HH:mm","disabledDate":_vm.disabledDate},on:{"change":function () { return _vm.onTimeChange(false); }}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.reason')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'reason',
        {
          rules: [
            {
              required: true,
              message: _vm.$t('tips.pleaseEnterReason'),
            } ],
        } ]),expression:"[\n        'reason',\n        {\n          rules: [\n            {\n              required: true,\n              message: $t('tips.pleaseEnterReason'),\n            },\n          ],\n        },\n      ]"}],attrs:{"autoSize":{
        minRows: 4,
        maxRows: 8,
      },"placeholder":_vm.$t('tips.pleaseEnterReason')}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.attachment')}},[_c('upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'attachments',
        {
          initialValue: [],
        } ]),expression:"[\n        'attachments',\n        {\n          initialValue: [],\n        },\n      ]"}],attrs:{"option":{ maxNum: 5, maxSize: 100, unit: 'M' }},on:{"loadingChange":function (status) { return (_vm.uploading = status); }}})],1),_c('a-form-item',{attrs:{"label":" ","colon":false}},[_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.takeLeave}},[_vm._v(_vm._s(_vm.$t('common.submit')))]),_c('a-button',{on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1),_c('group-transfer',{attrs:{"title":_vm.$t('message.addStudents'),"defaultCandidateData":_vm.candidate,"defaultSelectedData":_vm.selectedStudents},on:{"confirm":_vm.getSelectedData},model:{value:(_vm.groupTransferVisible),callback:function ($$v) {_vm.groupTransferVisible=$$v},expression:"groupTransferVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }